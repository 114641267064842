<template>
  <div class="blinker">&nbsp;&nbsp;</div>
</template>

<script>
export default {
  name: 'Colors'
}
</script>

<style lang="scss" scoped>
.blinker {
  background: var(--primary);
  display: inline-block;
  animation: flash 1s infinite;
  line-height: 1;
}
</style>