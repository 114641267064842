<template>
  <div class="colors">

    <a class="color" v-for="(color, index) in colors" :key="index" :style='[{"background-color": color.bg}, {"border-color": color.primary}]' href="javascript:void(0)" v-on:click="change(color)">
      <div class="inner" :style='{"background-color": color.primary}'></div>
    </a>

  </div>
</template>

<script>
export default {
  name: 'Colors',
  data: function() {
    return {
      colors: [
        {bg: '#2E3440', primary: '#D8DEE9', secondary: '#88C0D0', tertiary: '#B48EAD'},
        {bg: '#1B1725', primary: '#ecafed', secondary: '#226CE0', tertiary: '#5e5470'},
        {bg: '#2e3538', primary: '#DD2D4A', secondary: '#C5C5C5', tertiary: '#829191'},
        {bg: '#000000', primary: '#ffffff', secondary: '#cff702', tertiary: '#8B94A3'},
        {bg: '#ffffff', primary: '#000000', secondary: '#f03000', tertiary: '#6D676E'},
        {bg: '#0011ff', primary: '#ffffff', secondary: '#F22B29', tertiary: '#000044'},
        {bg: '#db2373', primary: '#ffb60a', secondary: '#F7AEF8', tertiary: '#ffffff'},
        {bg: '#EEC643', primary: '#7371FC', secondary: '#131520', tertiary: '#EEFEFF'}
      ]
    }
  },
  methods: {
    change: function(color) {
      this.$emit('change', color)
    }
  },
  mounted: function() {
    this.change(
      this.colors[3 + Math.round(Math.random())]
      //this.colors[Math.floor(Math.random() * this.colors.length)]
    )
  }
}
</script>

<style lang="scss" scoped>
.colors {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
}

.color {
  display: block;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--primary);
  position: relative;
  margin-bottom: 4px;
}

.inner {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  pointer-events: none;
}
</style>
