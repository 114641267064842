<template>
  <div class="nav tertiary">

    <div class="inner">

      <div class="home">
        <a class="route lores s12" href="javascript:void(0)" v-on:click="top">
          <span class="bold">liara.io</span>@LIARA.IO:~{{route.path == '/' ? '#' : route.path }}&Tab;<Blinker /><br>
          {{d}}
        </a>
      </div>

      <div class="cluster">
        <router-link class="lores s21" to="/">root</router-link>
        <router-link class="lores s21" to="/work">/work</router-link>
        <router-link class="lores s21" to="/bruch">/brüch</router-link>
      </div>

    </div>

  </div>
</template>

<script>
import Blinker from '@/components/Blinker.vue'

export default {
  name: 'Nav',
  props: {
    date: Date,
    route: Object
  },
  methods: {
    top: function() {
      document.getElementById('app').scrollTo(0,0)
    }
  },
  components: {
    Blinker
  },
  computed: {
    d: function() {
      let d = ""
      switch (this.date.getDay()) {
        case 0:
          d+="SUN"
          break;
        case 1:
          d+="MON"
          break;
        case 2:
          d+="TUE"
          break;
        case 3:
          d+="WED"
          break;
        case 4:
          d+="THU"
          break;
        case 5:
          d+="FRI"
          break;
        case 6:
          d+="SAT"
          break;
        default:
          break;
      }
      d+=" "+this.date.getDate()+" of "
      switch (this.date.getMonth()) {
        case 0:
          d+="JAN"
          break;
        case 1:
          d+="FEB"
          break;
        case 2:
          d+="MAR"
          break;
        case 3:
          d+="APR"
          break;
        case 4:
          d+="MAY"
          break;
        case 5:
          d+="JUN"
          break;
        case 6:
          d+="JUL"
          break;
        case 7:
          d+="AUG"
          break;
        case 8:
          d+="SEP"
          break;
        case 9:
          d+="OCT"
          break;
        case 10:
          d+="NOV"
          break;
        case 11:
          d+="DEC"
          break;
        default:
          break;
      }
      d+=" '"+(""+this.date.getFullYear()).substring(2)
      d+=" @ "+this.date.toLocaleTimeString()
      if (this.date.getDate()==22&&this.date.getMonth()==6) {
        d+=" (birthday: " + (this.date.getFullYear()-1998) + " y.o. 🎂)"
      }
      if (this.date.getDate()==31&&this.date.getMonth()==11 || this.date.getDate()==1&&this.date.getMonth()==0) {
        d+=" (new year: ~4.543 bn. y.o. 🌍)"
      }
      return d
    }
  }
}
</script>

<style lang="scss" scoped>
.route {
  display: block;
  padding: 0;
}

.nav {
  position: fixed;
  z-index: 1;
  width: 100vw;
}

.nav .inner {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between; 
}

.home, .cluster {
  display: inline-block;
}

.nav a:not(:last-of-type) {
  margin-right: 2rem;
}

.nav a.router-link-exact-active, .nav a.router-link-active:not([href='/']) {
  color: var(--bg);
  background-color: var(--tertiary);
  animation: none;
  position: relative;
}

@media only screen and (max-width: 720px) {
  .home {
    display: none;
  }
  .cluster {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .nav a:not(:last-of-type) {
    margin-right: 0;
  }
}
</style>