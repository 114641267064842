<template>
  <div class="loading" :class="loaded ? 'loaded' : 'not-loaded'">
    <div class="hourglass">

      <div class="element base">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 13 20" style="enable-background:new 0 0 13 20;" xml:space="preserve">
        <path :fill="color.bg" d="M12,0H1H0v1v1v1h1v4h1v1h1v1h1v1v1H3v1H2v1H1v4H0v1v1v1h1h11h1v-1v-1v-1h-1v-4h-1v-1h-1v-1H9v-1V9h1V8h1V7h1V3h1V2V1V0H12z
          M12,18v1H1v-1H12z M11,6h-1v1H9v1H8v1H7v1v1h1v1h1v1h1v1h1v3H2v-3h1v-1h1v-1h1v-1h1v-1V9H5V8H4V7H3V6H2V3h9V6z M12,2H1V1h11V2z"/>
        </svg>
      </div>

      <div class="element one" v-if="step==1">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 13 20" style="enable-background:new 0 0 13 20;" xml:space="preserve">
          <rect :fill="color.secondary" x="3" y="4" width="1" height="1"/>
          <rect :fill="color.secondary" x="5" y="4" width="1" height="1"/>
          <rect :fill="color.secondary" x="7" y="4" width="1" height="1"/>
          <rect :fill="color.secondary" x="9" y="4" width="1" height="1"/>
          <rect :fill="color.secondary" x="8" y="5" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="5" width="1" height="1"/>
          <rect :fill="color.secondary" x="4" y="5" width="1" height="1"/>
          <rect :fill="color.secondary" x="5" y="6" width="1" height="1"/>
          <rect :fill="color.secondary" x="7" y="6" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="7" width="1" height="1"/>
        </svg>
      </div>

      <div class="element two" v-if="step==2">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 13 20" style="enable-background:new 0 0 13 20;" xml:space="preserve">
          <rect :fill="color.secondary" x="7" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="5" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="8" y="5" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="5" width="1" height="1"/>
          <rect :fill="color.secondary" x="4" y="5" width="1" height="1"/>
          <rect :fill="color.secondary" x="5" y="6" width="1" height="1"/>
          <rect :fill="color.secondary" x="7" y="6" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="7" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="14" width="1" height="1"/>
        </svg>
      </div>

      <div class="element three" v-if="step==3">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 13 20" style="enable-background:new 0 0 13 20;" xml:space="preserve">
          <rect :fill="color.secondary" x="7" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="5" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="8" y="14" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="14" width="1" height="1"/>
          <rect :fill="color.secondary" x="5" y="6" width="1" height="1"/>
          <rect :fill="color.secondary" x="7" y="6" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="7" width="1" height="1"/>
          <rect :fill="color.secondary" x="9" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="3" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="4" y="14" width="1" height="1"/>
        </svg>
      </div>

      <div class="element four" v-if="step==4">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 13 20" style="enable-background:new 0 0 13 20;" xml:space="preserve">
          <rect :fill="color.secondary" x="7" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="5" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="8" y="14" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="14" width="1" height="1"/>
          <rect :fill="color.secondary" x="7" y="13" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 15 27)" width="1" height="1"/>
          <rect :fill="color.secondary" x="5" y="13" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 11 27)" width="1" height="1"/>
          <rect :fill="color.secondary" x="6" y="12" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 13 25)" width="1" height="1"/>
          <rect :fill="color.secondary" x="9" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="3" y="15" width="1" height="1"/>
          <rect :fill="color.secondary" x="4" y="14" width="1" height="1"/>
        </svg>
      </div>


    </div>

  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    color: Object
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      step: 0,
      loaded: false
    }
  },
  methods: {
    increment: function() {
      this.step++
      if (this.step > 4) {this.step = 1}

      if (document.fonts.check('1rem lores-12') && document.fonts.check('1rem lores-21-serif') && this.step==4) {
        this.loaded = true
      } else {
        setTimeout(
          this.increment,
          500
        )
      }
    }
  },
  mounted () {
    this.increment()
  }
}
</script>

<style lang="scss" scoped>
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 220797;
  background: var(--primary);
}

.loading .hourglass {
  width: 12.5vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading .hourglass .element {
  position: absolute;
  width: 100%;
  top: 0;
  transform: translateY(-50%);
}

.loading {
  transition: top 1.5s cubic-bezier(.31,.02,.34,.97);
  transition-delay: .5s;
}

.loading.loaded {
  top: 100vh;
}
</style>