<template>
  <div class="home content">

    <div class="">

      <div class="section lores s12">
        <p class="title myname lores s21">
          &gt; Liara Alexandria Brüchmann
        </p>
        <p>
          Graphic Designer, Photographer, &amp;amp; Full Stack Dev \n<br>
          @ Copenhagen, Denmark (55.6761° N, 12.5683° E);
        </p>
        <div class="contact">
          Get in touch &mdash; <a href="mailto:liarabruchmann@gmail.com">liarabruchmann@gmail.com</a>
        </div>
      </div>

      <div class="section lores s12">
        <p class="title lores s21">
          &gt; (Select) Work
        </p>
        <div class="clients">
          <router-link to="/work#sst" class="client">
            <p class="name lores s21">Sundhedsstyrelsen</p>
            <p class="category">Videography</p>
          </router-link>
          <router-link to="/work#zalando" class="client">
            <p class="name lores s21">Zalando</p>
            <p class="category">Photography</p>
          </router-link>
          <router-link to="/work#audi" class="client">
            <p class="name lores s21">Audi</p>
            <p class="category">Video- &amp; Photography</p>
          </router-link>
        </div>
        <br>
        <router-link to="/work" class="lores s12 tertiary">... more</router-link>
      </div>

      <div class="section">
        <p class="title lores s21">
          &gt; Version History
        </p>
        <div class="posting lores s12">
          <div class="lores s21">Udviklings- og Forenklingsstyrelsen</div>
          Front End Developer (MAR 2023 &mdash; PRESENT)
        </div>
        <div class="posting lores s12">
          <div class="lores s21">Mediano Media</div>
          Creative Lead (JAN 2021 &mdash; FEB 2023)
        </div>
        <div class="posting lores s12">
          <div class="lores s21">SimCorp</div>
          Client Driven Development (SEP 2019 &mdash; DEC 2020)
        </div>
        <div class="posting lores s12">
          <div class="lores s21">Aalborg University CPH</div>
          IT, Communications and New Media @ AAU CPH* (AUG 2017 &mdash; JUN 2019)<br>
          * Incomplete
        </div>
      </div>

      <div class="section">
        <p class="title lores s21">
          &gt; Technical Specifications
        </p>
        <p class="version lores s12">
          Pronouns: <a :href="pronounsPage" target="_blank" rel="noopener noreferrer">{{pronouns}}</a><br>
          Initial Release: 22 of JUL '98 ({{age}} years ago)<br>
          WPM: {{monkeytype ? monkeytype.personalBests.time['30'][0].wpm : 'loading...'}} &mdash; via <a :href="`http://monkeytype.com/profile/${monkeytype ? monkeytype.name : ''}`" target="_blank" rel="noopener noreferrer">monkeytype</a><br>
        </p>
      </div>

      <div class="section">
        <p class="title lores s21">
          &gt; More links
        </p>
        <p class="links lores s12">
          <router-link to="/hack">/hack</router-link> &mdash; Cyberpunk 2077 inspired hacking mini-game<br>
          <a href="http://mit.mediano.media" target="_blank" rel="noopener noreferrer">mit.mediano.media</a> &mdash; "koios" platform &mdash; vue2 based podcast player &amp; express/directus CMS<br>
          <a class="social" v-for="(url, social) in socials" :key="social" :href="url" target="_blank" rel="me">{{social}}</a>
        </p>
      </div>


    </div>

  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    date: Date,
    pronouns: String,
    pronounsPage: String,
    monkeytype: Object,
    socials: Object
  },
  computed: {
    version: function() {
      let major = this.age
      let minor = Math.floor((this.date - new Date('Fri Jul 22 1998 00:00:01 GMT+0200')) / 2629800000) % 12
      return major + '.' + minor
    },
    age: function() {
      return Math.abs(new Date(this.date - new Date('Fri Jul 22 1998 00:00:01 GMT+0200')).getUTCFullYear() - 1970)
    }
  }
}
</script>

<style lang="scss" scoped>
.section:not(:last-of-type) {
  padding-bottom: 1rem;
}

.contact a:not(:last-of-type) {
  margin-right: 2rem;
}

.client {
  display: inline-block;
  line-height: 1;
}

.client:not(:last-of-type) {
  margin-right: 2rem;
}

.client p {
  margin: 0;
}

@media only screen and (min-width: 1121px) {
  .clients {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 720px) {
  .client {
    display: block;
  }
  .client:not(:last-of-type) {
    margin-block-end: 1rem;
  }
  .contact a:not(:last-of-type) {
    margin-right: 0;
  }
  .contact a {
    display: block;
  }
}

.links .social:not(:last-of-type) {
  margin-right: 2rem;
}

.links .social {
  display: inline-block;
}
</style>
