<template>
  <div id="app" :style="vars">
    <div class="rightside">
      <div class="asterisk lores s12">
        <div class="l">&lt;li@ra/&gt;</div>
      </div>
    </div>
    <Nav :date="date" :route="route" :color="color" />
    <router-view :date="date" :route="route" :color="color" :pronouns="pronouns" :pronounsPage="pronounsPage"
      :monkeytype="monkeytype" :socials="socials" :linkinbio="linkinbio" />
    <div class="cursor" :class="[cursor.hover ? 'hover' : '', cursor.down ? 'down' : '']"
      :style="[{ left: cursor.x + 'px' }, { top: cursor.y + 'px' }]">
      <div class="inner"></div>
    </div>
    <Colors @change="change" />
    <div class="copyright lores s12" :class="'secret-' + secrets[secret].replace(/[^a-z0-9]|\s+|\r?\n|\r/gmi, '')">
      <div class="standard">liara.io &copy; {{ new Date().getFullYear() }}</div>
      <a href="javascript:void(0)" v-on:click="rotatesecret" class="secret">{{ secrets[secret] }}</a>
    </div>
    <Loading :color="color" />
  </div>
</template>

<script>
const axios = require('axios')

import Nav from '@/components/Nav.vue'
import Colors from '@/components/Colors.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'App',
  data: function () {
    return {
      cursor: {
        x: -256,
        y: -256,
        hover: false,
        down: false
      },
      color: {
        bg: '#000',
        primary: '#fff',
        secondary: '#aaa',
        tertiary: '#555'
      },
      date: new Date(),
      route: this.$route,
      pronouns: "she/they",
      pronounsPage: "https://en.pronouns.page/she&they",
      socials: {
        spotify: "https://open.spotify.com/artist/5LRdw0ugegfXvdkznn0viX",
        apple_music: "https://music.apple.com/us/artist/br%C3%BCch/1508414061",
        github: "https://github.com/alexampersandria",
        instagram: "https://instagram.com/bruchumlaut",
        bandcamp: "https://bruchumlaut.bandcamp.com/",
        soundcloud: "https://soundcloud.com/bruchumlaut/",
        pinterest: "https://pinterest.com/alexampersandria",
      },
      linkinbio: [
        {
          text: "Apart",
          url: "https://open.spotify.com/album/3vgehN7mEN8JoE6ZHEEycq"
        },
        {
          text: "CULT TAPE 001",
          url: "https://soundcloud.com/kavarimusic/sets/cult-tape-001-selected-noise"
        },
      ],
      secrets: [
        'click me',
        'vue > react',
        'trans rights',
        'with teeth best nin album',
        'new album soon^tm',
        'arail',
        'g?',
        '<li@ra/>',
        'sleep schedule? never heard of it',
        'yuropean',
        'keep refreshing to find all the easter <eggs>',
        'chock-full of color',
        'good girl',
        'support main',
        'keyboard addict (8 and counting)',
        'i never learned to solder',
        'ate without table: -3',
        'loading... ████░░░░░░░ 37%',
      ],
      secret: 0,
      monkeytype: null,
      wpm: 0
    }
  },
  components: {
    Nav,
    Colors,
    Loading
  },
  methods: {
    change: function (color) {
      this.color = color
    },
    rotatesecret: function () {
      this.secret = (this.secret + 1) % this.secrets.length
    },
    getMonkeytypeProfile: function (user) {
      axios.get(`https://api.monkeytype.com/users/${user}/profile`)
        .then((res) => {
          this.monkeytype = res.data.data
        })
        .catch((err) => {
          console.log(err);
        })
    }
  },
  computed: {
    vars: function () {
      return {
        '--primary': this.color.primary,
        '--bg': this.color.bg,
        '--secondary': this.color.secondary,
        '--tertiary': this.color.tertiary
      }
    }
  },
  watch: {
    $route(to, from) {
      document.getElementById('app').scrollTo(0, 0)
    }
  },
  mounted: function () {
    this.secret = Math.floor(Math.random() * this.secrets.length)
    this.getMonkeytypeProfile('liara')

    addEventListener('mousemove', (event) => {
      this.cursor.x = event.clientX
      this.cursor.y = event.clientY

      const element = event.target

      if (element) {
        if (element.classList) {
          if ((element.classList + "").includes('hoverable') || element.href) {
            this.cursor.hover = true
          } else {
            this.cursor.hover = false
          }
        }
      }
    })

    addEventListener("mousedown", (event) => {
      this.cursor.down = true
    })

    addEventListener("mouseup", (event) => {
      this.cursor.down = false
    })

    this.$watch(() => this.$route.params, (to, from) => {
      this.route = this.$route
    })

    setInterval(() => {
      this.date = new Date()
    }, 333)
  }
}
</script>

<style lang="scss">
@import url("https://use.typekit.net/axb3uam.css");

:root {
  font-size: 16px;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  font-family: neue-haas-grotesk-text, sans-serif;
  color: var(--primary);
  background-color: var(--bg);
  line-height: 1.6875;
  height: 100vh;
  overflow: auto;
}

::selection {
  color: var(--bg);
  background-color: var(--tertiary);
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 4px solid var(--bg);
  background-clip: padding-box;
  background: var(--secondary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

:target {
  box-shadow: 0 0 0 2px var(--bg), 0 0 0 3px var(--secondary);
}

.content {
  position: relative;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 8rem;
}

.toc {
  margin-bottom: 2rem;
  line-height: 1.2;
}

@media only screen and (min-width: 721px) {
  .centerstage {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .content,
  .content .centerstage {
    padding-bottom: 6rem;
  }
}

.nottoolong {
  max-width: 720px;
}

.secondary,
.secondary * {
  color: var(--secondary);
}

.tertiary,
.tertiary * {
  color: var(--tertiary);
}

.flipped,
.flipped * {
  color: var(--bg);
  background-color: var(--primary);
}

.flipped.secondary,
.flipped.secondary *,
.secondary .flipped,
.secondary .flipped *,
.flipped .secondary,
.flipped .secondary * {
  color: var(--bg);
  background-color: var(--secondary);
}

.flipped.tertiary,
.flipped.tertiary *,
.tertiary .flipped,
.tertiary .flipped *,
.flipped .tertiary,
.flipped .tertiary * {
  color: var(--bg);
  background-color: var(--tertiary);
}

.centered {
  text-align: center;
}

html,
html *,
* {
  transition: color .1s, background-color .1s;
  transition-delay: 0s;
}

a {
  color: var(--primary);
  color: var(--secondary);
  text-decoration: none;
}

a:not(.color):hover,
a:not(.color):focus,
a:not(.color):hover *,
a:not(.color):focus * {
  animation: flash-secondary 1s infinite;
  transition: none;
}

.tertiary a:hover,
.tertiary a:focus,
.tertiary a:hover *,
.tertiary a:focus *,
a.tertiary:hover,
a.tertiary:focus,
a.tertiary:hover *,
a.tertiary:focus * {
  animation: flash-tertiary 1s infinite;
  transition: none;
}

a:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bg), 0 0 0 3px var(--primary);
}

a[target="_blank"]:after {
  content: "^";
}

@keyframes flash {
  0% {
    background-color: var(--primary);
    color: var(--bg);
  }

  49% {
    background-color: var(--primary);
    color: var(--bg);
  }

  50% {
    background-color: transparent;
    color: var(--primary);
  }

  100% {
    background-color: transparent;
    color: var(--primary);
  }
}

@keyframes flash-secondary {
  0% {
    background-color: var(--secondary);
    color: var(--bg);
  }

  49% {
    background-color: var(--secondary);
    color: var(--bg);
  }

  50% {
    background-color: transparent;
    color: var(--secondary);
  }

  100% {
    background-color: transparent;
    color: var(--secondary);
  }
}

@keyframes flash-tertiary {
  0% {
    background-color: var(--tertiary);
    color: var(--bg);
  }

  49% {
    background-color: var(--tertiary);
    color: var(--bg);
  }

  50% {
    background-color: transparent;
    color: var(--tertiary);
  }

  100% {
    background-color: transparent;
    color: var(--tertiary);
  }
}

a p {
  pointer-events: none;
}

.cursor {
  position: fixed;
  display: block;
  z-index: 220798;
  pointer-events: none;
}

.cursor .inner {
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  background-color: var(--bg);
  border: 1px solid var(--primary);
  box-sizing: border-box;
  transition: .2s;
  position: relative;
  transform: translate(-50%, -50%);
  box-shadow: INSET 0 0 0 2px var(--bg), 0 0 0 2px var(--bg);
}

.cursor.hover .inner {
  width: 24px;
  height: 24px;
  background-color: var(--primary);
}

.cursor.down .inner {
  box-shadow: 0 0 0 2px var(--bg);
  background-color: var(--primary);
}

.lores.s12,
.lores.s12 * {
  font-family: lores-12, sans-serif;
  font-size: 1.2rem;
  font-weight: 400 !important;
}

.lores.s21,
.lores.s21 * {
  font-family: lores-21-serif, sans-serif;
  font-size: 2.1rem;
  font-weight: 400 !important;
}

.lores.s21 .bold,
.lores.s21 .bold *,
.lores.s12 .bold,
.lores.s12 .bold *,
.lores.s21.bold,
.lores.s21.bold *,
.lores.s12.bold,
.lores.s12.bold * {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: initial;
  margin: 0;
}

.copyright {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--tertiary);
  color: var(--bg);
  width: calc(100% - 4rem);
  text-align: center;
}

.copyright.secret-chockfullofcolor:hover {
  background: linear-gradient(to right,
      #e40303,
      #e40303 16.67%,
      #ff8c00 16.67%,
      #ff8c00 33.33%,
      #ffed00 33.33%,
      #ffed00 50%,
      #008026 50%,
      #008026 66.67%,
      #004dff 66.67%,
      #004dff 83.33%,
      #750787 83.33%,
      #750787);
}

.copyright.secret-transrights:hover,
.copyright.secret-goodgirl:hover {
  background: linear-gradient(to right,
      #5ccefa,
      #5ccefa 20%,
      #f6a8b7 20%,
      #f6a8b7 40%,
      #fff 40%,
      #fff 60%,
      #f6a8b7 60%,
      #f6a8b7 80%,
      #5ccefa 80%,
      #5ccefa);
}

.copyright.secret-chockfullofcolor:hover a,
.copyright.secret-transrights:hover a,
.copyright.secret-goodgirl:hover a {
  color: #000 !important;
}

.copyright a {
  display: block;
  color: var(--bg) !important;
  animation: none !important;
}

.copyright:not(:hover) .secret {
  display: none;
}

.copyright:hover .standard {
  display: none;
}

@media only screen and (max-width: 720px) {
  .cursor {
    display: none;
  }
}

@media only screen and (min-width: 721px) {

  html,
  html *,
  * {
    cursor: none !important;
  }
}

.rightside {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}

.rightside .asterisk {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(43.37%, -50%);
}

.rightside .asterisk .l {
  transform: rotate(-90deg);
  font-size: 20vh;
  color: transparent;
  -webkit-text-stroke: 1px var(--tertiary);
}
</style>
